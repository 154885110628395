import { AppBar, Avatar, Box } from '@coolblue-development/becky';
import ProfileMenu from './ProfileMenu';
import CatoLogo from './CatoLogo';
import StatusMenu from './StatusMenu';
import Username from './UserDetails';
import { useAppContext } from '../../../AppContext';
import Snowfall from 'react-snowfall';

export interface HeaderProps {
  instanceUrl: string;
}

const Header: React.FC<HeaderProps> = ({ instanceUrl }) => {
  const { agent } = useAppContext();

  return (
    <>
      <AppBar
        sx={{ zIndex: 2, position: 'relative' }}
        elevation={0}
        variant="outlined"
        position="static"
        logo={<CatoLogo />}
        title="Cato"
        actions={
          <>
            <StatusMenu />
            <Username name={agent?.getName() ?? ''} />
          </>
        }
        avatar={
          <Avatar>
            <ProfileMenu instanceUrl={instanceUrl} />
          </Avatar>
        }
      />
      <Box sx={{ width: '100%', height: '64px', top: '0', position: 'absolute', zIndex: 10000, pointerEvents: 'none' }}>
        <Snowfall snowflakeCount={50} />
      </Box>
    </>
  );
};

export default Header;
