import { Label, Typography } from '@coolblue-development/becky';
import { useAppContext } from '../../../../../AppContext';
import { useEffect, useState } from 'react';
import { formatDuration } from '../../../../../utils/formatDuration';
import { getVoiceContact } from '../../../../../utils/getVoiceContact';
import GlasshourIcon from '../../../Icons/GlasshourIcon';

const Timer = (): JSX.Element => {
  const { thirdPartyVoiceConnection, voiceContact } = useAppContext();
  const [duration, setDuration] = useState<number>(0);
  const [voiceContactState, setVoiceContactState] = useState<connect.ContactStateType>();
  const [isInbound, setIsInbound] = useState<boolean>(false);
  const [waitingTime, setWaitingTime] = useState<number>(0);

  let labelContent;

  useEffect(() => {
    const contact = getVoiceContact(voiceContact?.getContactId());

    setDuration(contact?.getStateDuration() ?? 0);
    setVoiceContactState(contact?.getState().type);
    setIsInbound(contact?.isInbound() ?? false);
  }, [voiceContact]);

  useEffect(() => {
    if (thirdPartyVoiceConnection) {
      setDuration(thirdPartyVoiceConnection?.getStateDuration());
    }

    const timerId = setInterval(() => setDuration(prev => prev + 1000), 1000);
    return () => clearInterval(timerId);
  }, [thirdPartyVoiceConnection]);

  useEffect(() => {
    const contact = getVoiceContact(voiceContact?.getContactId());

    const queueTimestamp = contact?.getQueueTimestamp()?.getTime() ?? 0;
    const queueDuration = Date.now() - queueTimestamp;

    setWaitingTime(queueDuration);
    const timerId = setInterval(() => setWaitingTime(prev => prev + 1000), 1000);
    return () => clearInterval(timerId);
  }, [voiceContact]);

  switch (voiceContactState) {
    case connect.ContactStateType.CONNECTED:
      labelContent = formatDuration(duration);
      break;
    case connect.ContactStateType.MISSED:
    case connect.ContactStateType.ERROR:
      labelContent = 'CALL MISSED';
      break;
    case connect.ContactStateType.ENDED:
      labelContent = 'CALL ENDED';
      break;
    default:
      if (!isInbound) {
        labelContent = 'CALLING...';
      } else {
        labelContent = formatDuration(waitingTime);
      }
  }

  return (
    <Label sx={{ background: 'rgba(255, 255, 255, 0.2)', marginBottom: '8px', padding: '8px', gap: '4px' }}>
      {voiceContactState === connect.ContactStateType.CONNECTING && isInbound && <GlasshourIcon color={'#fff'} />}
      <Typography variant="h4">{labelContent}</Typography>
    </Label>
  );
};

export default Timer;
